<template>
    <div class="advert">
        <div v-if="adsList.length > 0">
            <b-row>
                <b-col lg="3" md="6" v-for="adsItem in paginationList" :key="adsItem.id">
                    <MainCard
                        type="ad"
                        :media="adsItem.mediaType == 2 ? adsItem.file : adsItem.thumbFile"
                        :startDate="adsItem.startDate"
                        :endDate="adsItem.endDate"
                        :name="adsItem.titl"
                        :description="adsItem.content"
                        @details="goToDetails(adsItem.id)"
                        @delete="deleteAd(adsItem.id)"
                    ></MainCard>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center">
                    <ek-pagination
                        :items="adsList"
                        v-model="paginationList"
                        :pageLength="8"
                    ></ek-pagination>
                </b-col>
            </b-row>
        </div>
        <div v-else class="text-center mt-2">
            <h4>لا يوجد إعلانات</h4>
        </div>
    </div>
</template>

<script>
import MainCard from "@/app/admin/components/ElearningCard";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
    components: {
         MainCard 
    },
    data() {
        return {
            paginationList: []
        };
    },
    computed: {
        ...mapState({
            adverts: state => state.adverts.adverts,
            advertOptions: state => state.adverts.advertOptions
        }),
        ...mapGetters(["adsList"]),
    },
    methods: { 
        goToDetails(id) {
            this.$router.push({ path: `/admin/adverts/${id}` });
        },
        deleteAd(id) {
            this.deleteAd(id);
        },
        ...mapActions(["getAdsList" , "deleteAd"]),
    },
    created() {
        this.getAdsList()
    },
};
</script>


